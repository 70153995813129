<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <div class="form-title-group">
                    <div class="menu-active-mark" style="margin-top: 10px;"/>
                    <div class="form-title"><h1>紐付け解除</h1></div>
                  </div>
                  <div class="menu-bar"/>
                  <p class="form-text">企業グループと指定したユーザーの紐付けを解除することができます。</p>
                  <div v-if="hasValidationError || isUserDoesNotExist" class="error-area">
                    <p v-for="item in allValidationError" :key="item.validationText">
                      ・{{item.validationText}}
                    </p>
                    <p v-if="isUserDoesNotExist">・除籍可能なユーザーはいません。</p>
                  </div>
                  <div v-for="data of userList" :key="data.id" class="row"
                       style="display: contents; padding: 10px"
                  >
                    <div class="_large_jb_list72 shadow_0">
                      <div class="company-top-wrap userListFrame">
                        <span class="userList_name">{{data.name}}</span>
                        <p class="userList_mail">{{data.mailAddress}}</p>
                        <button type="button" class="btn-md full-width pop-login form-button userexpulsion-btn"
                                @click="expulsion(data)">紐付けを解除する
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";

    export default {
        name: "UserExpulsion",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        data: function () {
            return {
                userList: [],
                isUserDoesNotExist: false
            }
        },
        created() {
            this.search();
        },
        methods: {
            async search() {
                const response = await this.postApi("user/groupUsers", {
                    includeSelf: false
                });
                if (response) {
                    this.userList = response.data.groupUsers;
                    this.isUserDoesNotExist = this.userList.length === 0;
                }
            },
            async expulsion(data) {
                const response = await this.postApi("user/groupExpulsion", {
                    userId: data.id
                }, true);
                if (response) {
                    this.pushResult("除籍完了", "対象のユーザーを除籍しました")
                }
            }
        }
    }
</script>

<style scoped>

</style>
